const messages = {
  validation: {
    missingValue: {
      checkbox: 'Polje je obvezno.',
      default: 'Polje je obvezno.',
    },

    patternMismatch: {
      name: 'Dovoljene so samo črke.',
      surname: 'Dovoljene so samo črke.',
      email: 'E-naslov ni v pravilni obliki.',
      tax: 'Napačna oblika.',
      default: 'Napačna oblika.',
    },

    wrongLength: {
      under: 'Minimalna dolžina: {minLength} znakov.',
      over: 'Maksimalna dolžina: {maxLength} znakov.',
    },
  },
  submit: {
    addingUserFailed: 'Prišlo je do napake, poskusite ponovno.',
    userExists: 'S temi podatki ste se že prijavili.',
    error: 'Prišlo je do napake, poskusite ponovno.',
    userDontExist: 'Prišlo je do napake, poskusite ponovno.',
  },
};

export default messages;
