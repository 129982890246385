import firebase from 'firebase/app';
import 'firebase/firestore';

let db = null;

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
};

export function initializeDB() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }
  db = firebase.firestore();
}

export function getUsers(callback) {
  initializeDB();
  const data = [];
  db.collection('users').get().then(query => {
    query.forEach(doc => {
      data.push(doc.data());
    });
    callback(true, data);
  }).catch(error => {
    callback(false, error);
  });
}

export function addUser(data, callback) {
  db.collection('users').where('email', '==', data.email).where('tax', '==', data.tax).get()
    .then(querySnapshot => {
      if (querySnapshot.docChanges().length === 0) {
        db.collection('users').add(data)
          .then(result => {
            callback(false, result.id);
          })
          .catch(() => {
            callback('addingUserFailed', null);
          });
      } else {
        callback('userExists', null);
      }
    });
}
