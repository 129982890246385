import Bouncer from 'formbouncerjs';
import messages from './messages';
import { addUser } from '../db';

let submitInProgress = false;
let currentScreen = 'question-1';
let result = 0;

const showSubmitErrorMessage = error => {
  const messageErrorGeneral = document.querySelector('.js-form-error-general');
  messageErrorGeneral.innerText = messages.submit[error];
  messageErrorGeneral.classList.remove('hidden');
};

const hideSubmitErrorMessage = () => {
  const messageErrorGeneral = document.querySelector('.js-form-error-general');
  messageErrorGeneral.innerText = '';
  messageErrorGeneral.classList.add('hidden');
};

const disableSubmitButton = disable => {
  const submit = document.querySelector('.js-form-submit');
  if (disable) {
    submit.disabled = true;
    submit.classList.add('in-progress');
    submit.classList.add('disabled');
  } else {
    submit.disabled = false;
    submit.classList.remove('disabled');
    submit.classList.remove('in-progress');
  }
};

const submitForm = () => {
  hideSubmitErrorMessage();

  // eslint-disable-next-line no-unused-vars
  const bouncer = new Bouncer('.js-form', {
    disableSubmit: true,
    fieldClass: 'error',
    errorClass: 'form-item__error',
    messages: {
      ...messages.validation,
    },
  });

  document.addEventListener('bouncerFormValid', () => {
    if (!submitInProgress) {
      submitInProgress = true;
      disableSubmitButton(true);

      const data = {};

      data.result = result;
      const inputs = document.querySelectorAll('.js-form input');

      inputs.forEach(input => {
        if (input.type === 'checkbox') {
          data[input.id] = input.checked;
        } else {
          data[input.id] = input.value;
        }
      });

      const today = new Date();

      data.date = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
      data.time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

      const subscribeUser = async () => {
        const url = `/.netlify/functions/subscribe?email=${data.email}&name=${data.name}&surname=${data.surname}&phone=${data.phone}`;

        try {
          await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json())
            .then(res => {
              if (res.statusCode === 200) {
                addUser(data, error => {
                  if (error) {
                    showSubmitErrorMessage(error);
                    submitInProgress = false;
                    disableSubmitButton(false);
                  } else {
                    const formScreen = document.querySelector('[data-screen="form"]');
                    formScreen.classList.add('hidden');
                    const thankYouScreen = document.querySelector('[data-screen="thank-you"]');
                    thankYouScreen.classList.remove('hidden');
                  }
                });
              } else {
                showSubmitErrorMessage('error');
                submitInProgress = false;
                disableSubmitButton(false);
              }
            });
        } catch (err) {
          showSubmitErrorMessage('error');
          submitInProgress = false;
          disableSubmitButton(false);
        }
      };

      subscribeUser();
    }
  }, false);

  document.addEventListener('bouncerShowError', event => {
    event.target.closest('.js-form-item').classList.add('-has-error');
  }, false);

  document.addEventListener('bouncerRemoveError', event => {
    event.target.closest('.js-form-item').classList.remove('-has-error');
  }, false);
};

export default function initializeForm() {
  const btnOpenScreen = document.querySelectorAll('.js-open-screen');

  btnOpenScreen.forEach(el => {
    el.addEventListener('click', event => {
      let nextScreenEl;
      if (currentScreen.indexOf('question') !== -1) {
        const id = currentScreen.split('question-');
        const radioGroupElement = document.querySelector(`input[name="answer-${id[1]}"]:checked`);

        if (radioGroupElement) {
          const selectedValue = radioGroupElement.value;
          result += parseInt(selectedValue, 10);
          const nextScreenID = event.target.getAttribute('data-next-screen');
          const currentScreenEl = document.querySelector(`[data-screen="${currentScreen}"]`);

          if (nextScreenID.indexOf('question') !== -1) {
            nextScreenEl = document.querySelector(`[data-screen="${nextScreenID}"]`);
          } else if (nextScreenID.indexOf('result') !== -1) {
            if (result >= 0 && result < 5) {
              nextScreenEl = document.querySelector('[data-screen="result-1"]');
            } else if (result >= 5 && result < 10) {
              nextScreenEl = document.querySelector('[data-screen="result-2"]');
            } else {
              nextScreenEl = document.querySelector('[data-screen="result-3"]');
            }
          }

          currentScreenEl.classList.add('hidden');
          nextScreenEl.classList.remove('hidden');
          if (window.innerWidth < 1024) {
            nextScreenEl.scrollIntoView();
          }
          currentScreen = nextScreenID;
        } else {
          const screen = event.target.parentElement;
          const radioGroup = screen.querySelector('.radio-group');
          const error = screen.querySelector('.error');
          radioGroup.classList.add('-has-error');
          error.style.display = 'block';
        }
      } else {
        if (result >= 0 && result < 5) {
          nextScreenEl = document.querySelector('[data-screen="result-1"]');
        } else if (result >= 5 && result < 10) {
          nextScreenEl = document.querySelector('[data-screen="result-2"]');
        } else {
          nextScreenEl = document.querySelector('[data-screen="result-3"]');
        }
        nextScreenEl.classList.add('hidden');
        const formScreen = document.querySelector('[data-screen="form"]');
        formScreen.classList.remove('hidden');
        if (window.innerWidth < 1024) {
          formScreen.scrollIntoView();
        }
      }
    });
  });

  const radios = document.querySelectorAll('input[type="radio"]');
  radios.forEach(el => {
    el.addEventListener('click', event => {
      const radioGroup = event.target.parentElement.parentElement;
      radioGroup.classList.remove('-has-error');
      const error = radioGroup.querySelector('.error');
      error.style.display = 'none';
    });
  });

  const submit = document.querySelector('.js-form-submit');
  if (submit) {
    submit.addEventListener('click', submitForm, { once: true });
  }

  const checkboxParents = document.querySelectorAll('.js-checkbox-parent');
  checkboxParents.forEach(el => {
    const child = el.parentElement.parentElement.querySelector('.js-checkbox-child');
    const arrow = el.querySelector('.arrow');

    el.addEventListener('click', event => {
      event.preventDefault();
      if (child.classList.contains('hidden')) {
        child.classList.remove('hidden');
        arrow.classList.add('opened');
      } else {
        child.classList.add('hidden');
        arrow.classList.remove('opened');
      }
    });
  });

  const checks = document.querySelectorAll('input[type="checkbox"]');

  const termsAll = document.getElementById('terms-all');
  const termsMarketing = document.getElementById('terms-marketing');
  const termsMarketingSubchecks = termsMarketing.parentElement.querySelectorAll('.js-checkbox-child input[type="checkbox"]');
  const termsCommunication = document.getElementById('terms-communication');
  const termsCommunicationSubchecks = termsCommunication.parentElement.querySelectorAll('.js-checkbox-child input[type="checkbox"]');

  termsAll.addEventListener('change', event => {
    if (event.target.checked) {
      checks.forEach(el => {
        const temp = el;
        temp.checked = true;
      });
    } else {
      checks.forEach(el => {
        const temp = el;
        temp.checked = false;
      });
    }
  });

  termsMarketing.addEventListener('change', event => {
    document.querySelector('label[for="terms-marketing"]').classList.remove('minus');
    if (event.target.checked) {
      termsMarketingSubchecks.forEach(el => {
        const temp = el;
        temp.checked = true;
      });
    } else {
      termsMarketingSubchecks.forEach(el => {
        const temp = el;
        temp.checked = false;
      });
    }
  });

  termsCommunication.addEventListener('change', event => {
    document.querySelector('label[for="terms-communication"]').classList.remove('minus');
    if (event.target.checked) {
      termsCommunicationSubchecks.forEach(el => {
        const temp = el;
        temp.checked = true;
      });
    } else {
      termsCommunicationSubchecks.forEach(el => {
        const temp = el;
        temp.checked = false;
      });
    }
  });

  function subcheckNum(el) {
    let num = 0;
    el.forEach(subcheck => {
      if (subcheck.checked) {
        num++;
      }
    });
    return num;
  }

  termsMarketingSubchecks.forEach(el => {
    el.addEventListener('change', event => {
      if (event.target.checked) {
        termsMarketing.checked = true;
        const num = subcheckNum(termsMarketingSubchecks);
        document.querySelector('label[for="terms-marketing"]').classList.remove('minus');
        if (num < 2) {
          document.querySelector('label[for="terms-marketing"]').classList.add('minus');
        }
      } else {
        const num = subcheckNum(termsMarketingSubchecks);
        document.querySelector('label[for="terms-marketing"]').classList.remove('minus');
        if (num === 0) {
          termsMarketing.checked = false;
        } else {
          termsMarketing.checked = true;
          document.querySelector('label[for="terms-marketing"]').classList.add('minus');
        }
      }
    });
  });

  termsCommunicationSubchecks.forEach(el => {
    el.addEventListener('change', event => {
      if (event.target.checked) {
        termsCommunication.checked = true;
        const num = subcheckNum(termsCommunicationSubchecks);
        document.querySelector('label[for="terms-communication"]').classList.remove('minus');
        if (num < 5) {
          document.querySelector('label[for="terms-communication"]').classList.add('minus');
        }
      } else {
        const num = subcheckNum(termsCommunicationSubchecks);
        document.querySelector('label[for="terms-communication"]').classList.remove('minus');
        if (num === 0) {
          termsCommunication.checked = false;
        } else {
          termsCommunication.checked = true;
          document.querySelector('label[for="terms-communication"]').classList.add('minus');
        }
      }
    });
  });
}
