import { initializeDB } from './db';
import initializeForm from './form/form';
import CookieConsent from './cookie-consent';
import cookieOptionsSl from './cookie-options-sl.json';

// eslint-disable-next-line no-unused-vars
const consent = new CookieConsent(cookieOptionsSl);

initializeDB();
initializeForm();
